import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import { faGlobeEurope, faLock, faMobileAlt, faCheck, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { graphql, navigate } from "gatsby";
import SEO from "../components/seo";
import RoomListContainer from "../components/roomListContainer";
import IndexContent from "../components/indexContent";
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import PageWrapper from "../components/pageWrapper";
import { FormattedMessage } from 'react-intl';
import i18nMessages from '../i18n/sv.json';
import 'intl/locale-data/jsonp/sv';


const Index = (props) => {
	//const siteTitle = data.site.siteMetadata.title;

	/*
	const selectedLang = useSelector(state => state.selectedLang);

	// Redirect to english or swedish if user has not selected a language.
	if (selectedLang == undefined || selectedLang == '') {
		const urlLang = getRedirectLang();
		if (urlLang == '/' && typeof window !== 'undefined') {
			navigate(urlLang, {replace: true});
		}
	}
	*/

	return (
		<PageWrapper location={props.location} lang="sv" i18nMessages={i18nMessages}>
			<IndexContent location={props.location} lang="sv" />
		</PageWrapper>
	)
}

export default Index

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`