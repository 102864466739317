import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import { faGlobeEurope, faLock, faMobileAlt, faCheck, faCheckSquare, faHeart, faDoorClosed, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { graphql, Link, navigate } from "gatsby";
import SEO from "../components/seo";
import RoomListContainer from "../components/roomListContainer";
import { ChatFooter } from "../components/chatFooter";
import Container from 'react-bootstrap/Container';
import { useSelector, useDispatch } from 'react-redux';
import PageWrapper from "../components/pageWrapper";
import { FormattedMessage, injectIntl } from 'react-intl';
import plusLogo from "../../static/logo-plus-550x120.png";
import { setShowSignInModal, setEnteredName } from "../state/actions";
import LiveStats from "../components/liveStats";
import Form from 'react-bootstrap/Form';
import BrowseContent from '../components/browseContent';

const IndexContent = (props) => {
	const totalUnread = useSelector(state => state.totalUnread);
	const loggedIn = useSelector(state => state.loggedIn);
	const enteredName = useSelector(state => state.enteredName);
	const initialLoader = useSelector(state => state.initialLoader);
	const dispatch = useDispatch();

	const updateEnteredName = (evt) => {
		let textInput = evt.target.value;
		dispatch(setEnteredName(textInput));
	}

	const content = (
		<BrowseContent langUrl="/sv" sort={2} />
	);

	if (initialLoader) {
		return (
			<div className="chatPageWrapper page">
				<div className='dcLoader medium preLoader' />
			</div>
		);
	}

	return (
		<div className="">
			<SEO
				title={totalUnread == 0 ? props.intl.formatMessage({ id: 'home' }) : totalUnread + " " + props.intl.formatMessage({ id: 'dM' })}
				description={props.intl.formatMessage({ id: 'indexSeoDesc' })}
				pagePath="/">
			</SEO>

			{content}
		</div>
	)
}

export default injectIntl(IndexContent)
