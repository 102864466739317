import React from "react";
import {navigate} from "gatsby";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import AppConfig from '../appconfig';
import RegisterForm from './registerForm';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  setLoggedIn,
  reset,
  addRoomData,
  setAllowDm,
  setBlockedUsernames,
  setAvatarUrl,
  setShowAdModal
} from "../state/actions";
import { FormattedMessage, injectIntl } from 'react-intl';



class LiveStats extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			chattingNow: 0,
			sentMessages: 0,
			sentImages: 0, 
			isLoading: true
		};

		this.setIsLoading = this.setIsLoading.bind(this);
		this.getLiveStats = this.getLiveStats.bind(this);
	}

	componentDidMount() {
		this.getLiveStats();
	}
	

 	setIsLoading(value) {
		this.setState({
			isLoading: value
		});
 	}

 	getLiveStats() {
		let url = AppConfig.API_URL + 'getLiveStats';

	 	fetch(url, {
  			credentials: 'include'
		})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					this.setState({
						chattingNow: result.onlineUsers,
						sentMessages: result.sentMessages,
						sentImages: result.sentImages,
						isLoading: false
					});

				} else {
					if (result.errorCode == 101) {
						window.location.reload();
					}
				}

				this.setState({
					isLoading: false
				});
				
			},
			error => {
				this.setState({
					isLoading: false
				});
			}
		);

		return false;
 	}

 	render() {

		return (
			<div className="frontStatWrapper">
				{this.state.chattingNow > 0 &&
					<div className="frontStats">
						{/*}<span className="onlineStatIcon"><span className="onlineIcon"></span></span>{*/}
						<span className="statValue">{this.state.chattingNow} </span>
						<span className="statText online"><FormattedMessage id="chattingNow" /></span>
					</div>
				}

				{this.state.sentImages > 0 &&
					<div className="frontStats">
						<span className="statValue">{this.state.sentImages} </span>
						<span className="statText"><FormattedMessage id="sentImages" /></span>
					</div>
				}
				
				{this.state.sentMessages > 0 &&
					<div className="frontStats">
						<span className="statValue">{this.state.sentMessages} <FormattedMessage id="million" /> </span>
						<span className="statText"><FormattedMessage id="sentMessages" /></span>
					</div>
				}
			</div>
		);
		
	 }
}


function mapStateToProps(state) {
	const { loggedIn, messages } = state
	return { 
		loggedIn: loggedIn,
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setLoggedIn: setLoggedIn,
		reset: reset,
		addRoomData: addRoomData,
		setAllowDm: setAllowDm,
		setBlockedUsernames: setBlockedUsernames,
		setAvatarUrl: setAvatarUrl,
		setShowAdModal: setShowAdModal
	},
	dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LiveStats);
