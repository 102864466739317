import React from "react";
import styles from "./roomListing.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import flagSv from '../../static/flag_sweden_round.png';
import flagEn from '../../static/flag_england_round.png';



export default function RoomListing(props) {
	
	return (
		<Row className={styles.listingwrapper}>
			<Row className={styles.listingrow}>
				<div className={styles.listingcol1}>
					<div className={styles.language}> <img src={props.language == 'sv' ? flagSv : flagEn} alt="language flag" /> </div>	
				</div>
				<div className={styles.listingcontent}>
					<p className={styles.listingtitle}>{props.title}</p>
					<div className={styles.listingdescription}>{props.description}</div>
				</div>
			</Row>
			<Row className={styles.listingbottomrow}>
				<div className={styles.listingcol1}>
					<div className={props.sfw ? "listingCircleSfw" : "listingCircleNsfw"}><FontAwesomeIcon style={{width: '2em', height: '2em'}} icon={faCircle} /></div>
				</div>
				<div className={""}>
					{props.sfw ? "SFW" : "NSFW"}
				</div>
			</Row>
		</Row>

	);
}
