import React from "react";
import { navigate } from 'gatsby';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import RoomListing from "./roomListing"
import styles from "./roomListContainer.module.css"
import { FormattedMessage, injectIntl } from 'react-intl';


class RoomListContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
		};
	}


 	render() {
 		let lang = this.props.lang;

		return (
			<div className={styles.roomListContainer}>
	        	<Row>
					<Col onClick={ () => navigate(lang == 'sv' ? '/sv/room/lobby' : '/room/lobby-english') }>
				    	<RoomListing 
				    		display={true} language={lang} title={"Lobby"} 
				    		description={this.props.intl.formatMessage({ id: 'lobbyDesc' })} 
				    		sfw={true} />
					</Col>

					<Col onClick={ () => navigate(lang == 'sv' ? '/sv/room/bilder' : '/room/images') }>
				    	<RoomListing 
				    		display={true} language={lang} title={this.props.intl.formatMessage({ id: 'images' })}
				    		description={this.props.intl.formatMessage({ id: 'imagesDesc' })} 
				    		sfw={false} />
					</Col>

					<Col onClick={ () => navigate(lang == 'sv' ? '/sv/room/tjejchatten' : '/room/girls-chat') }>
				    	<RoomListing 
				    		display={true} language={lang} title={this.props.intl.formatMessage({ id: 'girlsChat' })}
				    		description={this.props.intl.formatMessage({ id: 'girlsChatDesc' })} 
				    		sfw={false} />
					</Col>

					<Col onClick={ () => navigate(lang == 'sv' ? '/sv/room/sex' : '/room/sex-english') }>
				    	<RoomListing 
				    		display={true} language={lang} title="Sex"
				    		description={this.props.intl.formatMessage({ id: 'sexDesc' })} 
				    		sfw={false} />
					</Col>

					<Col onClick={ () => navigate(lang == 'sv' ? '/sv/room/bilder-sfw' : '/room/images-sfw') }>
				    	<RoomListing 
				    		display={true} language={lang} title={this.props.intl.formatMessage({ id: 'images' }) + ' SFW'}
				    		description={this.props.intl.formatMessage({ id: 'imagesSfwDesc' })} 
				    		sfw={true} />
					</Col>

					<Col onClick={ () => navigate(lang == 'sv' ? '/sv/room/video' : '/room/video-english') }>
				    	<RoomListing 
				    		display={true} language={lang} title="Video"
				    		description={this.props.intl.formatMessage({ id: 'videoDesc' })} 
				    		sfw={false} />
					</Col>

					{/*}
					<Col onClick={ () => navigate(lang == 'sv' ? '/sv/room/rollspel' : '/room/roleplay') }>
				    	<RoomListing 
				    		display={true} language={lang} title={this.props.intl.formatMessage({ id: 'roleplay' })}
				    		description={this.props.intl.formatMessage({ id: 'roleplayDesc' })} 
				    		sfw={false} />
					</Col>

					<Col onClick={ () => navigate(lang == 'sv' ? '/sv/room/dating' : '/room/dating-english') }>
				    	<RoomListing 
				    		display={true} language={lang} title="Dating"
				    		description={this.props.intl.formatMessage({ id: 'datingDesc' })} 
				    		sfw={false} />
					</Col>
					{*/}
				</Row>
			</div>
		);
	}
}

export default injectIntl(RoomListContainer);